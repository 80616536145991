import { Box, Button, Paper, TableContainer, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { MdOutlineCancel, MdDoneAll } from "react-icons/md";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  useDeleteTransactionMutation,
  useSearchTransactionsQuery,
  useUpdateTransactionProcessMutation,
} from "../services/TransactionService";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import SecuredComponent from "../components/common/SecuredComponent";
import { DomainType } from "../presentation/helpers";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import { DataGridService } from "../core/dataGridService";
import CustomToolbar from "../components/common/CustomToolbar";
import { TransactionsPaymentProcessingTable } from "../data/TransactionsPaymentProcessingTable";
import { RemittanceTransactionsTable } from "../data/RemittanceTransactionsTable";
import { FiEye } from "react-icons/fi";
import { Link } from "react-router-dom";
import { AllRemittancesTable } from "../data/AllRemittancesTable";

const TransactionTypeRemittances = () => {
  const dataGridService = new DataGridService("Transaction");
  const [filter, setFilter] = useState({});
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [open, setOpen] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const { data, isLoading } = useSearchTransactionsQuery(filter);
  const [deleteTransaction] = useDeleteTransactionMutation();
  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [settleStatus, setSettleStatus] = useState("");
  const [UpdateTransactionProcess] = useUpdateTransactionProcessMutation();
  const [transactionReference, setTransactionreference] = useState("");

  const [sortModel, setSortModel] = useState([
    { field: "created", sort: "desc" },
  ]);
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };

  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };

  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel?.page,
      size: paginationModel?.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };


  const handlePaymentProcess = async (event) => {
    event.preventDefault();
    const currentDateTime = new Date()
      .toISOString()
      .replace(/T/, " ")
      .replace(/\..+/, "");
    const updateTransactionPayload = {
      errorcode: "PORTAL",
      orderreference: data?.data?.data.orderId,
      paymenttypedescription: data?.data?.data.description,
      requestreference: `SET_ON_${currentDateTime}`,
      settlestatus: settleStatus,
      sitereference: `SET_BY_${data?.data?.data.email}`,
      transactionreference: transactionReference,
      transactionId: transactionReference,
      securityresponseaddress: "NOT_DEFINED",
      securityresponsepostcode: "NOT_DEFINED",
      securityresponsesecuritycode: "NOT_DEFINED",
      maskedpan: "NOT_DEFINED",
    };
    await UpdateTransactionProcess(updateTransactionPayload)
      .then((response) => {
        if (response.data) {
          SuccessToast(
            `Payment ${
              settleStatus === "2" ? "Canceled" : "Processed"
            } successfully`
          );
          setSettleStatus("");
          setTransactionreference("");
          handleClose();
        } else {
          ErrorToast("Failed to Update");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to edit category");
      });
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedTransaction(null);
  };


  let filteredData = data?.data?.data.filter(transaction => transaction.type === "REMITTANCE");

    if (!filteredData) {
    filteredData = [];
    }

  const columns = AllRemittancesTable().map((column) => ({
    ...column,
    sortable: true,
  }));

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel]);


  return (
    <SecuredComponent
      type="PAGE"
      requiredDomainTypes={[DomainType.BACK_OFFICE, DomainType.SUPERMAN]}
    >
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">All Remittances</Typography>
        </Box>
        <CustomAlertDialog
          open={open}
          handleClose={handleClose}
          handleSubmit={handlePaymentProcess}
          title={"Complete Transaction"}
          subTitle={`Are you sure you want to complete transaction: ${selectedTransaction?.id}?`}
          negativeButtonText={"No"}
          positiveButtonText={"Yes"}
          icon={<MdDoneAll size={30} />}
          fullWidth={true}
        />
        <CustomAlertDialog
          open={openCancel}
          handleClose={handleClose}
          handleSubmit={handlePaymentProcess}
          title={"Cancel Transaction"}
          subTitle={`Are you sure you want to cancel  transaction: ${selectedTransaction?.id}?`}
          negativeButtonText={"No"}
          positiveButtonText={"Yes"}
          icon={<MdOutlineCancel size={30} />}
          fullWidth={true}
        />
        <Box>
          <TableContainer component={Paper}>
            <DataGrid
              autoHeight
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "gray",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
              filterMode="server"
              paginationMode="server"
              sortingMode="server"
              loading={isLoading}
              rowCount={filteredData.length ?? 0}
              columns={columns}
              rows={filteredData}
              getRowId={(row) => row.id}
              disableColumnSelector
              disableDensitySelector
              components={{
                Toolbar: () => <CustomToolbar data={data} />,
              }}
              sortModel={sortModel}
              paginationModel={paginationModel}
              filterModel={filterModel}
              onPaginationModelChange={handlePaginationModelChange}
              onSortModelChange={handleSortModelChange}
              onFilterModelChange={handleFilterModelChange}
            />
          </TableContainer>
        </Box>
      </Box>
    </SecuredComponent>
  );
};

export default TransactionTypeRemittances;
