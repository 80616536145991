import { Box, Button, Paper, TableContainer, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useSearchRemittanceActivitiesQuery } from "../services/RemittanceActivityService";
import { RemittanceActivityTable } from "../data/RemittanceActivityTable";
import queryString from "query-string";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import moment from "moment";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { Print } from "@mui/icons-material";
import SecuredComponent from "../components/common/SecuredComponent";
import { DomainType } from "../presentation/helpers";
import { DataGridService } from "../core/dataGridService";
import CustomToolbar from "../components/common/CustomToolbar";

const RemittanceActivity = () => {
  const dataGridService = new DataGridService("RemittanceActivity");

  const [filter, setFilter] = useState({});
  const [selectedRemitanceActivity, setSelectedRemittanceActivity] =
    useState(null);
  const [open, setOpen] = useState(false);
  const { data: remittanceActivities, isLoading } =
    useSearchRemittanceActivitiesQuery(filter);
  const printRef = useRef();
  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRemittanceActivity(null);
  };

  const handleViewActivity = async (row) => {
    setOpen(true);
    setSelectedRemittanceActivity(row);
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel.page,
      size: paginationModel.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };

  const columns = RemittanceActivityTable(handleViewActivity);

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel]);

  return (
    <SecuredComponent
      type="PAGE"
      requiredDomainTypes={[DomainType.BACK_OFFICE, DomainType.SUPERMAN]}
    >
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">Remittance Activities</Typography>
        </Box>
        <Dialog
          open={open}
          fullWidth={true}
          onClose={handleClose}
          sx={{ mx: "auto", my: "0px" }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <DialogTitle>Activity Details </DialogTitle>
            <ReactToPrint
              trigger={() => (
                <Button>
                  <Print />
                </Button>
              )}
              content={() => printRef.current}
            />
          </Box>{" "}
          <DialogContent ref={printRef}>
            <DialogContentText>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Id:{" "}
                <span style={{ opacity: 0.7 }}>
                  {selectedRemitanceActivity?.id}
                </span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Created By:{" "}
                <span style={{ opacity: 0.7 }}>
                  {selectedRemitanceActivity?.createdby ?? "N/A"}
                </span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Activity:{" "}
                <span style={{ opacity: 0.7 }}>
                  {selectedRemitanceActivity?.activity}
                </span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Component:{" "}
                <span style={{ opacity: 0.7 }}>
                  {selectedRemitanceActivity?.component}
                </span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Date:{" "}
                <span style={{ opacity: 0.7 }}>
                  {moment(selectedRemitanceActivity?.created).format("LLL") ??
                    "N/A"}
                </span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Updated:{" "}
                <span style={{ opacity: 0.7 }}>
                  {moment(selectedRemitanceActivity?.updated).format("LLL") ??
                    "N/A"}
                </span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Last Modified by:{" "}
                <span style={{ opacity: 0.7 }}>
                  {moment(selectedRemitanceActivity?.lastModifiedby).format(
                    "LLL"
                  ) ?? "N/A"}
                </span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Request:{" "}
                <span style={{ opacity: 0.7 }}>
                  {selectedRemitanceActivity?.request ?? "null"}
                </span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Response:{" "}
                <span style={{ opacity: 0.7 }}>
                  {selectedRemitanceActivity?.response ?? "null"}
                </span>
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>ok</Button>
          </DialogActions>
        </Dialog>

        <Box>
          <TableContainer component={Paper}>
            <DataGrid
              autoHeight
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "gray",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
              filterMode="server"
              sortingMode="server"
              paginationMode="server"
              slotProps={{
                panel: {
                  sx: {
                    top: "-120px !important",
                  },
                },
              }}
              loading={isLoading}
              rowCount={remittanceActivities?.size ?? 0}
              columns={columns}
              rows={remittanceActivities?.data ?? []}
              getRowId={(row) => row.id}
              disableColumnSelector
              disableDensitySelector
              components={{
                Toolbar: () => <CustomToolbar data={remittanceActivities} />,
              }}
              sortModel={sortModel}
              paginationModel={paginationModel}
              filterModel={filterModel}
              onPaginationModelChange={handlePaginationModelChange}
              onSortModelChange={handleSortModelChange}
              onFilterModelChange={handleFilterModelChange}
            />
          </TableContainer>
        </Box>
      </Box>
    </SecuredComponent>
  );
};

export default RemittanceActivity;
