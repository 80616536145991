import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import queryString from "query-string";
import { GridToolbar, DataGrid } from "@mui/x-data-grid";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { CurrenciesTable } from "../data/CurrenciesTable";
import {
  useCreateCurrencyMutation,
  useDeleteCurrencyMutation,
  useUpdateCurrencyMutation,
} from "../services/CurrencyService";
import { useSearchCurrencyQuery } from "../services/CurrencyService";
import CurrencyModal from "../components/modals/CurrencyModal";
import SecuredComponent from "../components/common/SecuredComponent";
import { DomainType } from "../presentation/helpers";
import { DataGridService } from "../core/dataGridService";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import CustomToolbar from "../components/common/CustomToolbar";

const Currencies = () => {
  const dataGridService = new DataGridService("Currencies");
  const [filter, setFilter] = useState({});
  const [deleteCurrency] = useDeleteCurrencyMutation();
  const [type, setType] = useState("");
  const [open, setOpen] = useState(false);
  const [openCurrencyModal, setOpenCurrency] = useState(false);
  const { data: currencyData, isLoading: isCurrencyLoading } =
    useSearchCurrencyQuery(filter);
  const [currencyRequest, setCurrencyRequest] = useState(null);
  const [createCurrency] = useCreateCurrencyMutation();
  const [updateCurrency] = useUpdateCurrencyMutation();

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );
  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleOpenAddCurrency = () => {
    setOpenCurrency(true);
    setType("ADD");
  };
  const handleCloseAddCurrency = () => {
    setOpenCurrency(false);
    setCurrencyRequest(null);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    if (["countryId"].includes(name)) {
      setCurrencyRequest((prevcurrency) => ({
        ...prevcurrency,
        [name]: value?.id,
      }));
    } else {
      setCurrencyRequest((prevcurrency) => ({
        ...prevcurrency,
        [name]: value,
      }));
    }
  };

  const handleSubmitAddCurrency = async () => {
    await createCurrency(currencyRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Currency created successfully");
          handleClose();
        } else {
          ErrorToast("Failed to create currency");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create currency");
      });
  };
  const handleSubmitUpdateCurrency = async () => {
    await updateCurrency(currencyRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Currency updated succesfully");
          handleClose();
        } else {
          ErrorToast("Failed to update currency ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update currency");
      });
  };

  const handleSetFilter = () => {
    setFilter({
      page: paginationModel.page,
      size: paginationModel.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort.toUpperCase(),
      sortBy: sortModel[0]?.field,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setOpenCurrency(false);
    setCurrencyRequest(null);
  };

  const handleSubmit = async () => {
    await deleteCurrency(currencyRequest?.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Currency deleted succesfully");
          handleClose();
        } else {
          ErrorToast("Failed to delete Currency");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete Currency");
      })
      .finally(() => {
        handleClose();
      });
  };
  const handleEditAction = (row) => {
    setCurrencyRequest(row);
    setType("UPDATE");
    setOpenCurrency(true);
  };
  const handleDeleteAction = (row) => {
    setOpen(true);
    setCurrencyRequest(row);
  };
  const handleViewCurrency = (row) => {
    setType("VIEW");
    setOpenCurrency(true);
    setCurrencyRequest(row);
  };
  const columns = CurrenciesTable({
    handleViewCurrency,
    handleEditAction,
    handleDeleteAction,
  });

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel]);

  return (
    <SecuredComponent
      type="PAGE"
      requiredDomainTypes={[DomainType.BACK_OFFICE, DomainType.SUPERMAN]}
    >
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">Currencies</Typography>

          <Button
            variant="contained"
            color="primary"
            startIcon={<FiPlus />}
            sx={{ borderRadius: "20px" }}
            onClick={handleOpenAddCurrency}
          >
            Add Currency
          </Button>
        </Box>
        <CurrencyModal
          open={openCurrencyModal}
          handleClose={handleCloseAddCurrency}
          handleFormChange={handleFormChange}
          handleSubmitCurrency={
            type === "ADD"
              ? handleSubmitAddCurrency
              : handleSubmitUpdateCurrency
          }
          currency={currencyRequest}
          type={type}
        />
        <CustomAlertDialog
          open={open}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title={"Delete Currency"}
          subTitle={`Are you sure you want to delete ${currencyRequest?.name}?`}
          negativeButtonText={"No"}
          positiveButtonText={"Yes"}
          icon={<FiTrash2 size={30} />}
          fullWidth={true}
        />

        <Box>
          <DataGrid
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "gray",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
            autoHeight
            slotProps={{
              panel: {
                sx: {
                  top: "-120px !important",
                },
              },
            }}
            filterMode="server"
            paginationMode="server"
            disableColumnSelector
            disableDensitySelector
            loading={isCurrencyLoading}
            rowCount={currencyData?.size ?? 0}
            columns={columns}
            rows={currencyData?.data ?? []}
            getRowId={(row) => row.id}
            components={{
              Toolbar: () => <CustomToolbar data={currencyData} />,
            }}
            sortModel={sortModel}
            paginationModel={paginationModel}
            filterModel={filterModel}
            onPaginationModelChange={handlePaginationModelChange}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
          />
        </Box>
      </Box>
    </SecuredComponent>
  );
};
export default Currencies;
