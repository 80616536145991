import { Box } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import moment from "moment";

export const InventoryTable = ({ handleDeleteAction, handleEditAction }) => {
  return [
    {
      field: "created",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,

      headerName: "Created",
      valueGetter: ({ value }) => (value ? moment(value).format("LL") : "N/A"),
    },
    {
      field: "product",
      flex: 1.1,
      headerAlign: "center",
      align: "center",
      headerName: "Product Name",
      valueGetter: ({ value }) =>
        `${value?.name}-${value?.categories[0]?.label ?? "N/A"}`,
    },

    {
      field: "price",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      filterable: false,

      headerName: "Price",
      valueGetter: ({ value }) => `${value.currency} ${value.amount}`,
    },
    {
      field: "quantity",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: "Quantity",
    },
    {
      field: "minStockLevel",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
      headerName: "Min stock level",
    },
    // {
    //   field: "merchant",
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   headerName: "Merchant",
    //   valueGetter: ({ value }) => value?.name ?? "N/A",
    // },
    {
      field: "warehouse",
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Warehouse",
      valueGetter: ({ value }) => value?.name ?? "N/A",
    },
    {
      field: "actions",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
      sortable: false,
      headerName: "Actions",
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Tooltip arrow placement="top" title="View">
            <Link to={`/pages/inventory/${row.id}`}>
              <IconButton color="primary">
                <FiEye size={18} />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip arrow placement="top" title="Edit">
            <IconButton
              color="primary"
              onClick={() => {
                handleEditAction(row);
              }}
            >
              <FiEdit size={18} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow placement="top" title="Delete">
            <IconButton
              color="error"
              onClick={() => {
                handleDeleteAction(row);
              }}
            >
              <FiTrash2 size={18} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];
};
