import { Box, Grid, Paper, Typography, IconButton } from "@mui/material";
import React, { useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useGetRemittanceAttemptQuery } from "../services/RemittanceAttemptService";
import moment from "moment";
import { MdBusiness } from "react-icons/md";
import LoadingPage from "../components/LoadingPage";
import { KeyboardBackspace, Print } from "@mui/icons-material";
import { Button } from "@mui/material";
import ReactToPrint from "react-to-print";
import { remittanceJobStatus } from "../components/common/dropdownsData";

const SingleRemittanceAttempt = () => {
  const { id } = useParams();
  const { data: attemptInfo, isLoading } = useGetRemittanceAttemptQuery(id);

  const printRef = useRef();

  if (attemptInfo?.data) {
    let attempt = attemptInfo.data;
    return (
      <Box
        sx={{
          pt: "80px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", pb: "20px" }}
        >
          <Link to={-1}>
            <Button variant="outlined">
              <KeyboardBackspace /> BACK
            </Button>
          </Link>
          <Typography variant="h5"> Remittance Attempt</Typography>
        </Box>

        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
          ref={printRef}
        >
          <Box
            sx={{ display: "flex", justifyContent: "space-between" }}
            ref={printRef}
          >
            <ReactToPrint
              trigger={() => (
                <Button>
                  <Print />
                </Button>
              )}
              content={() => printRef.current}
            />
          </Box>{" "}
          {/* <Divider /> */}
          <Grid
            container
            spacing={3}
            sx={{ mt: 1, justifyContent: "space-evenly" }}
          >
            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    {/* <FiUser /> */}
                    <MdBusiness />
                  </IconButton>
                </Box>
                <Box>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    ID:{" "}
                    <span style={{ opacity: 0.7 }}>{attempt?.id ?? "N/A"}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Attempt Id:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {attempt?.attemptId ?? "N/A"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Order Id:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {attempt?.orderId ?? "N/A"}
                    </span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Transaction Id:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {attempt?.transactionId ?? "N/A"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    External Id:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {attempt?.externalId ?? "N/A"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Type:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {remittanceJobStatus[attempt?.type] ?? "N/A"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Request Sent:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {attempt?.requestSent ?? "N/A"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Response:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {attempt?.response ?? "N/A"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Callback Received:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {attempt?.callbackReceived ?? "N/A"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Callback Response:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {attempt?.callbackResponse ?? "N/A"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Last Modified By:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {attempt?.lastModifiedBy ?? "N/A"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Date Created:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(attempt?.created).format("LLL")}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Last Updated:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(attempt?.updated).format("LLL")}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default SingleRemittanceAttempt;
