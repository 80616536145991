import { Box, Button, Paper, TableContainer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { GridToolbar, DataGrid } from "@mui/x-data-grid";
import { RemittanceConfigurationsTable } from "../data/RemittanceConfigurationsTable";
import {
  useDeleteRemittanceConfigurationMutation,
  useSearchRemittanceConfigurationsQuery,
} from "../services/RemittanceConfigurationService";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import SecuredComponent from "../components/common/SecuredComponent";
import { DomainType } from "../presentation/helpers";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import { DataGridService } from "../core/dataGridService";
import CustomToolbar from "../components/common/CustomToolbar";

const RemittanceConfiguration = () => {
  const dataGridService = new DataGridService("RemittanceConfiguration");
  const [filter, setFilter] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedConfiguration, setSelectedConfiguration] = useState(null);
  const { data, isLoading } = useSearchRemittanceConfigurationsQuery(filter);
  const [
    deleteRemittanceConfiguration,
    { isSuccess: isConfigurationDeleteSuccess },
  ] = useDeleteRemittanceConfigurationMutation();

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel.page,
      size: paginationModel.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    await deleteRemittanceConfiguration(selectedConfiguration.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Configuration deleted succesfully");
        } else {
          ErrorToast("Failed to delete Configuration");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete Configuration");
      })
      .finally(() => {
        setOpen(false);
      });
  };
  const handleDeleteAction = async (row) => {
    setOpen(true);
    setSelectedConfiguration(row);
  };
  const columns = RemittanceConfigurationsTable(handleDeleteAction);

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel, isConfigurationDeleteSuccess]);

  return (
    <SecuredComponent
      type="PAGE"
      requiredDomainTypes={[DomainType.BACK_OFFICE, DomainType.SUPERMAN]}
    >
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">Remittance Configurations</Typography>
          <Link
            to="/pages/remittance/configuration/add"
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<FiPlus />}
              sx={{ borderRadius: "20px" }}
            >
              Add Configuration
            </Button>
          </Link>
        </Box>
        <CustomAlertDialog
          open={open}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title={"Delete Configuration"}
          subTitle={`Are you sure you want to delete this row?`}
          negativeButtonText={"No"}
          positiveButtonText={"Yes"}
          icon={<FiTrash2 size={30} />}
          fullWidth={true}
        />
        <Box>
          <TableContainer component={Paper}>
            <DataGrid
              autoHeight
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "gray",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
              loading={isLoading}
              filterMode="server"
              paginationMode="server"
              slotProps={{
                panel: {
                  sx: {
                    top: "-120px !important",
                  },
                },
              }}
              rowCount={data?.data?.size ?? []}
              rows={data?.data?.data ?? 0}
              columns={columns}
              disableColumnSelector
              disableDensitySelector
              getRowId={(row) => row.id}
              components={{
                Toolbar: () => <CustomToolbar data={data} />,
              }}
              sortModel={sortModel}
              paginationModel={paginationModel}
              filterModel={filterModel}
              onPaginationModelChange={handlePaginationModelChange}
              onSortModelChange={handleSortModelChange}
              onFilterModelChange={handleFilterModelChange}
            />
          </TableContainer>
        </Box>
      </Box>
    </SecuredComponent>
  );
};

export default RemittanceConfiguration;
