import {
  Box,
  Button,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  CircularProgress,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { productTypes } from "../components/common/dropdownsData";
import { Add } from "@mui/icons-material";
import AvailableWeightComponent from "../components/common/AvailableWeightComponent";
import AvailableVolumeComponent from "../components/common/AvailableVolumeComponent";
import AvailableMeasurementComponent from "../components/common/AvailableMeasurementComponent";
import { addProductDescription_Step_1 } from "../components/common/descriptions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { availableVolumeRequest } from "../models/availableVolume";
import { availableDimensionRequest } from "../models/availableDimension";
import { availableWeightRequest } from "../models/availableWeight";
import { useUpdateProductMutation } from "../services/ProductService";
import { updateProductRequest } from "../models/product";
import { Link, useParams } from "react-router-dom";
import LoadingPage from "../components/LoadingPage";
import { useProductContext } from "../presentation/shared/hooks/useProductContextHook";
import Chip from "@mui/material/Chip";
import {
  useCreateInventoryMediaMutation,
  useDeleteInventoryMediaMutation,
  useUpdateInventoryMediaMutation,
} from "../services/InventoryMediaService";
import { InventoryMediaTable } from "../data/InventoryMediaTable";
import { inventoryMedia } from "../models/inventoryMedia";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const EditProductDescription = () => {
  const { id } = useParams();
  const [updateProduct, { isLoading: isUpdateProductLoading }] =
    useUpdateProductMutation();
  const [
    createInventoryMedia,
    {
      isLoading: isInventoryMediaCreateLoading,
      isSuccess: isInventoryMediaCreateSuccess,
    },
  ] = useCreateInventoryMediaMutation();
  const [
    updateInventoryMedia,
    {
      isLoading: isInventoryMediaUpdateLoading,
      isSuccess: isInventoryMediaUpdateSuccess,
    },
  ] = useUpdateInventoryMediaMutation();
  const [
    deleteInventoryMedia,
    {
      isLoading: isInventoryMediaDeleteLoading,
      isSuccess: isInventoryMediaDeleteSuccess,
    },
  ] = useDeleteInventoryMediaMutation();
  const [productRequest, setProductRequest] = useState(updateProductRequest);
  const { productData, refetchProduct, isProductLoading } = useProductContext();
  const [expanded, setExpanded] = React.useState(false);
  const [type, setType] = useState("");
  const [openMediaDialog, setOpenMediaDialog] = useState(false);
  const [openDeleteMediaDialog, setOpenDeleteMediaDialog] = useState(false);
  const [inventoryMediaRequest, setInventoryMediaRequest] =
    useState(inventoryMedia);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleProductTypeChange = (event) => {
    const { name, value } = event.target;
    setProductRequest((prevProduct) => ({ ...prevProduct, [name]: value }));
  };
  const handleProductNameChange = (event) => {
    const { name, value } = event.target;
    setProductRequest((prevProduct) => ({ ...prevProduct, [name]: value }));
  };
  const handleServiceChange = (event) => {
    const { name, checked } = event.target;
    setProductRequest((prevProduct) => ({ ...prevProduct, [name]: checked }));
  };

  const handlePlatformSKUChange = (event) => {
    const { name, checked } = event.target;
    setProductRequest((prevProduct) => ({ ...prevProduct, [name]: checked }));
  };
  const handleEnabledChange = (event) => {
    const { name, checked } = event.target;
    setProductRequest((prevProduct) => ({ ...prevProduct, [name]: checked }));
  };
  const handleAvailableWeightComponent = () => {
    setProductRequest((prevProduct) => ({
      ...prevProduct,
      availableWeight: [
        ...prevProduct?.availableWeight,
        availableWeightRequest,
      ],
    }));
  };
  const handleAvailableVolumeComponent = () => {
    setProductRequest((prevProduct) => ({
      ...prevProduct,
      availableVolume: [
        ...prevProduct?.availableVolume,
        availableVolumeRequest,
      ],
    }));
  };
  const handleAvailableMeasurementComponent = () => {
    setProductRequest((prevProduct) => ({
      ...prevProduct,
      availableMeasurement: [
        ...prevProduct?.availableMeasurement,
        availableDimensionRequest,
      ],
    }));
  };
  const handleCustomerAmountAcceptedChange = (event) => {
    const { name, checked } = event.target;
    setProductRequest((prevProduct) => ({ ...prevProduct, [name]: checked }));
  };
  const handleLicensingDocumentRequiredChange = (event) => {
    const { name, checked } = event.target;
    setProductRequest((prevProduct) => ({ ...prevProduct, [name]: checked }));
  };

  const handleUpdateProduct = async (event) => {
    event.preventDefault();

    await updateProduct(productRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Product updated succesfully");
        } else {
          ErrorToast("Failed to update product");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update product");
      });
  };

  const initProductRequest = () => {
    if (productData) {
      setProductRequest(productData);
    }
  };

  const handleInventoryMediaChange = (event) => {
    const { name, value, files } = event.target;
    if (["media"].includes(name)) {
      const selectedFile = files[0];

      setInventoryMediaRequest((prev) => ({
        ...prev,
        filename: selectedFile.name,
        fileUrl: URL.createObjectURL(selectedFile),
        mimeType: selectedFile.type,
        size: selectedFile.size,
        productId: id,
      }));
    } else {
      setInventoryMediaRequest((prev) => ({
        ...prev,
        [name]: value,
        productId: id,
      }));
    }
  };
  const handleCreateInventoryMedia = async (event) => {
    event.preventDefault();
    await createInventoryMedia(inventoryMediaRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Media added succesfully");
          handleCloseDialog();
        } else {
          ErrorToast("Failed to add media");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to add media");
      });
  };
  const handleUpdateInventoryMedia = async (event) => {
    event.preventDefault();

    await updateInventoryMedia(inventoryMediaRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Media updated succesfully");
          handleCloseDialog();
        } else {
          ErrorToast("Failed to update media");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update media");
      });
  };
  const handleDeleteInventoryMedia = async (event) => {
    event.preventDefault();

    await deleteInventoryMedia(inventoryMediaRequest?.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Media deleted succesfully");
          handleCloseDialog();
        } else {
          ErrorToast("Failed to delete media ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete media ");
      });
  };
  const handleCloseDialog = () => {
    setOpenMediaDialog(false);
    setOpenDeleteMediaDialog(false);
    setInventoryMediaRequest(inventoryMediaRequest);
  };
  const handleShowAddInventoryMediaDialog = () => {
    setType("ADD");
    setOpenMediaDialog(true);
  };
  const handleMediaEditAction = (row) => {
    setType("UPDATE");
    setInventoryMediaRequest(row);
    setOpenMediaDialog(true);
  };
  const handleMediaDeleteAction = (row) => {
    setInventoryMediaRequest(row);
    setOpenDeleteMediaDialog(true);
  };
  const handleMediaViewAction = (row) => {
    setType("VIEW");
    setInventoryMediaRequest(row);
    setOpenMediaDialog(true);
  };

  const inventoryMediaTable = InventoryMediaTable({
    handleDeleteAction: handleMediaDeleteAction,
    handleEditAction: handleMediaEditAction,
    handleViewAction: handleMediaViewAction,
  });

  useEffect(() => {
    initProductRequest();
  }, [productData]);

  if (productData) {
    return (
      <Box>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
            maxWidth: "100%",
            margin: "0 auto",
            overflow: "hidden",
          }}
        >
          <Typography
            color={"gray"}
            sx={{ fontStyle: "italic", fontFamily: "sans-serif", mb: 10 }}
          >
            {addProductDescription_Step_1}
          </Typography>

          <Grid container spacing={2}>
            <Grid item md={12}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
                <Box
                  sx={{
                    mt: 4,
                    display: "flex",
                    gap: 2,
                  }}
                >
                  <TextField
                    label="Product name"
                    variant="outlined"
                    required
                    disabled={isUpdateProductLoading}
                    value={productRequest.name}
                    name="name"
                    fullWidth
                    onChange={handleProductNameChange}
                    size="small"
                  />

                  <FormControl fullWidth size="small" required>
                    <InputLabel id="demo-select-small-label">
                      Product type
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      label="Product type"
                      required
                      disabled={isUpdateProductLoading}
                      value={productRequest?.type}
                      name="type"
                      onChange={handleProductTypeChange}
                    >
                      {productTypes.map(({ value, label }, index) => (
                        <MenuItem key={index} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                {productRequest?.type === "PHYSICAL_GOOD" && (
                  <>
                    <Paper
                      sx={{
                        boxShadow: "none !important",
                        borderRadius: "12px",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "divider",
                        p: "5px",
                        overflow: "hidden",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <Typography color="primary" variant="button">
                          Add Available weight
                        </Typography>
                        <Button
                          disabled={isUpdateProductLoading}
                          variant="contained"
                          onClick={handleAvailableWeightComponent}
                        >
                          <Add />
                        </Button>
                      </Box>
                      {productRequest &&
                        productRequest.availableWeight.map(
                          (availableWeight, key) => (
                            <AvailableWeightComponent
                              componentKey={key}
                              disabled={isUpdateProductLoading}
                              availableWeight={availableWeight}
                              setProductRequest={setProductRequest}
                            />
                          )
                        )}
                    </Paper>

                    <Paper
                      sx={{
                        boxShadow: "none !important",
                        borderRadius: "12px",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "divider",
                        p: "5px",
                        overflow: "hidden",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <Typography color="primary" variant="button">
                          Add Available Volume
                        </Typography>
                        <Button
                          disabled={isUpdateProductLoading}
                          variant="contained"
                          onClick={handleAvailableVolumeComponent}
                        >
                          <Add />
                        </Button>
                      </Box>

                      {productRequest &&
                        productRequest.availableVolume.map(
                          (availableVolume, key) => (
                            <AvailableVolumeComponent
                              componentKey={key}
                              disabled={isUpdateProductLoading}
                              availableVolume={availableVolume}
                              setProductRequest={setProductRequest}
                            />
                          )
                        )}
                    </Paper>

                    <Paper
                      sx={{
                        boxShadow: "none !important",
                        borderRadius: "12px",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor: "divider",
                        p: "5px",
                        overflow: "hidden",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <Typography color="primary" variant="button">
                          Add Available Dimension
                        </Typography>
                        <Button
                          disabled={isUpdateProductLoading}
                          variant="contained"
                          onClick={handleAvailableMeasurementComponent}
                        >
                          <Add />
                        </Button>
                      </Box>
                      {productRequest &&
                        productRequest.availableMeasurement.map(
                          (availableMeasurement, key) => (
                            <AvailableMeasurementComponent
                              componentKey={key}
                              disabled={isUpdateProductLoading}
                              availableMeasurement={availableMeasurement}
                              setProductRequest={setProductRequest}
                            />
                          )
                        )}
                    </Paper>
                  </>
                )}

                {/* <Divider variant="middle">
                  <Chip variant="outlined" label="Product configuation" />
                </Divider> */}

                {/* <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                      More Product configuation
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ mt: 1, alignItems: "center", gap: 5 }}>
                      <FormGroup>
                        <span>
                          <FormControlLabel
                            name="enabled"
                            checked={productRequest.enabled}
                            onChange={handleEnabledChange}
                            disabled={isUpdateProductLoading}
                            control={<Checkbox />}
                            label="Enable product after creation"
                          />
                        </span>
                      </FormGroup>
                      <FormGroup>
                        <span>
                          <FormControlLabel
                            name="platformSKU"
                            checked={productRequest.platformSKU}
                            onChange={handlePlatformSKUChange}
                            disabled={isUpdateProductLoading}
                            control={<Checkbox />}
                            label="Platform stock keeping unit?"
                          />
                        </span>
                      </FormGroup>
                      <FormGroup>
                        <span>
                          <FormControlLabel
                            name="service"
                            checked={productRequest.service}
                            onChange={handleServiceChange}
                            disabled={isUpdateProductLoading}
                            control={<Checkbox />}
                            label="Service"
                          />
                        </span>
                      </FormGroup>
                      <FormGroup>
                        <span>
                          <FormControlLabel
                            name="customerAmountAccepted"
                            checked={productRequest.customerAmountAccepted}
                            disabled={isUpdateProductLoading}
                            onChange={handleCustomerAmountAcceptedChange}
                            control={<Checkbox />}
                            label="Customer amount accepted?"
                          />
                        </span>
                      </FormGroup>
                      <FormGroup>
                        <span>
                          <FormControlLabel
                            name="licensingDocumentRequired"
                            checked={productRequest.licensingDocumentRequired}
                            onChange={handleLicensingDocumentRequiredChange}
                            disabled={isUpdateProductLoading}
                            control={<Checkbox />}
                            label="Licensing document required?"
                          />
                        </span>
                      </FormGroup>
                    </Box>
                  </AccordionDetails>
                </Accordion> */}
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", mt: 6, justifyContent: "space-between" }}>
            <Link to={"/pages/products"}>
              <Button
                disabled={isUpdateProductLoading}
                variant="contained"
                color="error"
              >
                {isUpdateProductLoading && (
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "5px",
                    }}
                  />
                )}
                Cancel
              </Button>
            </Link>

            <Button
              disabled={isUpdateProductLoading}
              variant="contained"
              onClick={handleUpdateProduct}
            >
              {isUpdateProductLoading && (
                <CircularProgress
                  style={{ height: "20px", width: "20px", marginRight: "5px" }}
                />
              )}
              Save
            </Button>
          </Box>
        </Paper>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default EditProductDescription;
