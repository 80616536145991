import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton, TextField, Typography } from "@mui/material";

const CustomAlertDialog = ({
  open,
  handleClose,
  icon,
  title,
  subTitle,
  negativeButtonText,
  positiveButtonText,
  fullWidth,
  contentForSelectedRow,
  modalType,
  handleRetrigger,
  isClicked
}) => {
  const [inputValue, setInputValue] = React.useState("");
  const [hasNoValue, setHasNoValue] = React.useState(true);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setHasNoValue(value.trim() === "");
  };

  return (
    <Dialog
      open={open}
      fullWidth={fullWidth}
      onClose={handleClose}
      sx={{ mx: "auto", my: "0px" }}
    >
      <DialogTitle>
        <IconButton
          sx={{
            color: "#049bf5",
            backgroundColor: "rgba(4,155,245, 0.2) !important",
          }}
        >
          {icon}
        </IconButton>{" "}
        {title}
      </DialogTitle>
      <DialogContent>
        <div>
          <Typography sx={{ color: "grey", pb: "30px" }} variant="body1">
            {subTitle}
          </Typography>
        </div>
        <TextField
          label="Transaction reference"
          variant="outlined"
          sx={{ mx: 2 }}
          value={inputValue}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error" variant="contained">
          {negativeButtonText}
        </Button>
        <Button
          onClick={() => handleRetrigger(inputValue)}
          variant="contained"
          disabled={hasNoValue || isClicked}
        >
          {positiveButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomAlertDialog;
