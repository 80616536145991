import { Box, Button, Paper, TableContainer, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { MdOutlineRedo } from "react-icons/md";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  useDeleteTransactionMutation,
  useSearchTransactionsQuery,
  useRemittanceTransactionsQuery,
} from "../services/TransactionService";
import SecuredComponent from "../components/common/SecuredComponent";
import { DomainType } from "../presentation/helpers";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import { DataGridService } from "../core/dataGridService";
import CustomToolbar from "../components/common/CustomToolbar";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { RemittanceTransactionsTable } from "../data/RemittanceTransactionsTable";
import {useRetryRemittanceMutation} from "../services/RemittanceAttemptService"

const TransactionRemittance = () => {
  const dataGridService = new DataGridService("Transaction");
  const [filter, setFilter] = useState({});
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [open, setOpen] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  // const { data, isLoading } = useRemittanceTransactionsQuery({
  //   status:"REMITTANCE_CREATED"
  // });
  const { data, isLoading } = useSearchTransactionsQuery(filter);
  const [retryRemittance] = useRetryRemittanceMutation();
  const [isClicked, setIsClicked]= useState(false)
  
  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState([
    { field: "created", sort: "desc" },
  ]);
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };

  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };

  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel?.page,
      size: paginationModel?.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };

  const handleSubmit = async () => {
    setIsClicked(true)
    try {    
      const response = await retryRemittance(selectedTransaction.orderId)
      if (response.data) {
        SuccessToast(
         "Remittance successfully retriggered"
        );
        setOpen(false);
        setOpenCancel(false);
        setIsClicked(false)
        
      } else {
        ErrorToast("Failed to Retrigger remittance");
        setOpen(false);
        setOpenCancel(false);
        setIsClicked(false)
      }
    } catch (error) {
      ErrorToast("Failed to retrigger remittance");
      setOpen(false);
      setOpenCancel(false);
      setIsClicked(false);
    }
  };

  const handleRetriggerAction = (row) => {
    setOpen(true);
    setSelectedTransaction(row);
  };


  const handleClose = () => {
    setOpen(false);
    setSelectedTransaction(null);
  };

  let filteredData = data?.data?.data.filter(transaction => transaction.status === "REMITTANCE_CREATED");

  if (!filteredData) {
  filteredData = [];
  }




  const columns = RemittanceTransactionsTable(handleRetriggerAction)

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel]);


  return (
    <SecuredComponent
      type="PAGE"
      requiredDomainTypes={[DomainType.BACK_OFFICE, DomainType.SUPERMAN]}
    >
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6">Pending Remittances</Typography>
        </Box>
        <CustomAlertDialog
          open={open}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title={"Retrigger Remittance"}
          subTitle={`Are you sure you want to retrigger remittance: ${selectedTransaction?.id}?`}
          negativeButtonText={"No"}
          positiveButtonText={"Yes"}
          icon={<MdOutlineRedo size={30} />}
          fullWidth={true}
          isClicked={isClicked}
        />
      
        <Box>
          <TableContainer component={Paper}>
            <DataGrid
              autoHeight
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "gray",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
              filterMode="server"
              paginationMode="server"
              sortingMode="server"
              loading={isLoading}
              rowCount={filteredData.length ?? 0}
              columns={columns}
              rows={filteredData}
              getRowId={(row) => row.id}
              disableColumnSelector
              disableDensitySelector
              components={{
                Toolbar: () => <CustomToolbar data={data} />,
              }}
              sortModel={sortModel}
              paginationModel={paginationModel}
              filterModel={filterModel}
              onPaginationModelChange={handlePaginationModelChange}
              onSortModelChange={handleSortModelChange}
              onFilterModelChange={handleFilterModelChange}
            />
          </TableContainer>
        </Box>
      </Box>
    </SecuredComponent>
  );
};

export default TransactionRemittance;
