import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { remittanceUrl } from "../presentation/shared/ApiUrls";
import queryString from "query-string";
import { queryStringOptions } from "../core/queryStringOptions";

export const remittanceAttemptApi = createApi({
  reducerPath: "remittanceAttemptApi",
  baseQuery: fetchBaseQuery({
    baseUrl: remittanceUrl,
    prepareHeaders: (headers) => {
      headers.append(
        "Authorization",
        `Bearer ${sessionStorage.getItem("accessToken")}`
      );
      return headers;
    },
  }),
  refetchOnReconnect: true,

  tagTypes: ["remittanceattempts"],
  endpoints: (builder) => ({
    getAllRemittanceAttempts: builder.query({
      query: (filterRemittanceRequest) => ({
        url: `/attempts/?${filterRemittanceRequest}`,
        method: "GET",
      }),
      providesTags: ["remittanceattempts"],
    }),

    getRemittanceAttempt: builder.query({
      query: (id) => ({
        url: `/attempts/${id}`,
        method: "GET",
      }),
      providesTags: ["remittanceattempts"],
    }),

    searchRemittanceAttempts: builder.query({
      query: (filterRemittanceRequest) => ({
        url: `/attempts?${queryString.stringify(
          filterRemittanceRequest,
          queryStringOptions
        )}`,
        method: "GET",
      }),
      providesTags: ["remittanceattempts"],
    }),
    updateRemittanceAttempt: builder.mutation({
      query: (updateRemittanceRequest) => ({
        url: "/attempts",
        method: "PUT",
        body: updateRemittanceRequest,
      }),
      invalidatesTags: ["remittanceattempts"],
    }),
    createRemittanceAttempt: builder.mutation({
      query: (addRemittanceRequest) => ({
        url: "/attempts",
        method: "POST",
        body: addRemittanceRequest,
      }),
      invalidatesTags: ["remittanceattempts"],
    }),

    deleteRemittanceAttempt: builder.mutation({
      query: (id) => ({
        url: `/attempts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["remittanceattempts"],
    }),
    getRemittanceAttemptsForOneRemittanceCreated: builder.query({
      query: (orderId) => ({
        url: `/attempts?orderId=${orderId}`,
        method: "GET",
      }),
      providesTags: ["remittanceattempts"],
    }),
    retryRemittance: builder.mutation({
      query: (orderId) => ({
        url: `/jobs/initiate/${orderId}`,
        method: "POST",
      }),
      providesTags: ["Transactions"],
    }),
  }),
});

export const {
  useGetAllRemittanceAttemptsQuery,
  useGetRemittanceAttemptQuery,
  useCreateRemittanceAttemptMutation,
  useDeleteRemittanceAttemptMutation,
  useUpdateRemittanceAttemptMutation,
  useSearchRemittanceAttemptsQuery,
  useGetRemittanceAttemptsForOneRemittanceCreatedQuery,
  useRetryRemittanceMutation,
} = remittanceAttemptApi;
