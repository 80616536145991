import React, { useState } from "react";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const CustomToolbar = ({ data }) => {
  const [exportFormat, setExportFormat] = useState("excel");
  const [showDropdown, setShowDropdown] = useState(false);

  const handleFormatChange = (event) => {
    setExportFormat(event.target.value);
  };

  const getExportData = () => {
    if (data?.data?.data) {
      return data.data.data;
    } else if (data?.data) {
      return data.data;
    }
    return data || [];
  };

  const handleExportToExcel = () => {
    const exportData = getExportData();

    if (exportData.length === 0) {
      console.error("No data to export");
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Payments");
    XLSX.writeFile(workbook, "TransactionLimits.xlsx");
  };

  const handleExportToCSV = () => {
    const exportData = getExportData();

    if (exportData.length === 0) {
      console.error("No data to export");
      return;
    }

    const csvData = exportData.map((row) =>
      Object.values(row)
        .map((value) => (value === null ? "" : value))
        .join(",")
    );

    const csvBlob = new Blob([csvData.join("\n")], {
      type: "text/csv;charset=utf-8;",
    });
    saveAs(csvBlob, "TransactionLimits.csv");
  };

  const handleExport = () => {
    if (exportFormat === "excel") {
      handleExportToExcel();
    } else {
      handleExportToCSV();
    }
    setShowDropdown(false); // Hide dropdown after exporting
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 1,
      }}
    >
      <GridToolbar />
      <Box>
        {showDropdown && (
          <FormControl variant="outlined">
            <InputLabel id="export-format-label">Export As</InputLabel>
            <Select
              labelId="export-format-label"
              value={exportFormat}
              onChange={handleFormatChange}
              label="Export As"
            >
              <MenuItem value="excel">Excel</MenuItem>
              <MenuItem value="csv">CSV</MenuItem>
            </Select>
          </FormControl>
        )}
        <Button
          variant="contained"
          onClick={() => setShowDropdown((prev) => !prev)}
          sx={{ mx: 2 }}
        >
          {showDropdown ? "Cancel" : "Export"}
        </Button>

        {showDropdown && (
          <Button variant="contained" onClick={handleExport} sx={{ ml: 1 }}>
            Download
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CustomToolbar;
