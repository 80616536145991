import { Box, Button, Paper, TableContainer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { ForexTable } from "../data/ForexTable";
import {
  useCreateForexMutation,
  useDeleteForexMutation,
  useSearchForexesQuery,
  useUpdateForexMutation,
} from "../services/ForexService";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import * as XLSX from "xlsx";
import ForexModal from "../components/modals/ForexModal";
import { DataGridService } from "../core/dataGridService";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import CustomToolbar from "../components/common/CustomToolbar";

const Forex = () => {
  const dataGridService = new DataGridService("Forex");
  const [filter, setFilter] = useState({});
  const [open, setOpen] = useState(false);
  const [openForexModal, setOpenForexModal] = useState(false);
  const [deleteForex] = useDeleteForexMutation();
  const [updateForex] = useUpdateForexMutation();
  const [createForex] = useCreateForexMutation();
  const { data: forexData, isLoading } = useSearchForexesQuery(filter);
  const [selectedForex, setSelectedForex] = useState(null);
  const [type, setType] = useState("");

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );
  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenForexModal(false);
  };

  const handleFormChange = (event) => {
    const { name, value, checked } = event.target;
    if (["enabled"].includes(name)) {
      setSelectedForex((prevForex) => ({ ...prevForex, [name]: checked }));
    } else {
      setSelectedForex((prevForex) => ({ ...prevForex, [name]: value }));
    }
  };

  const handleSetFilter = () => {
    setFilter({
      page: paginationModel.page,
      size: paginationModel.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort.toUpperCase(),
      sortBy: sortModel[0]?.field,
    });
  };

  const handleCloseForexModal = () => {
    setOpenForexModal(false);
    setSelectedForex(null);
  };
  const handleOpenForexModal = () => {
    setType("ADD");
    setOpenForexModal(true);
  };

  const handleDeleteAction = (row) => {
    setSelectedForex(row);
    setOpen(true);
  };
  const handleEditAction = (row) => {
    setType("UPDATE");
    setSelectedForex(row);
    setOpenForexModal(true);
  };
  const handleViewAction = (row) => {
    setType("VIEW");
    setSelectedForex(row);
    setOpenForexModal(true);
  };

  const handleSubmitUpdateForex = async () => {
    await updateForex(selectedForex)
      .then((response) => {
        if (response.data) {
          SuccessToast("Forex updated succesfully");
          handleClose();
        } else {
          ErrorToast("Failed to update Forex");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update Forex");
      });
  };
  const handleSubmitCreateForex = async () => {
    await createForex(selectedForex)
      .then((response) => {
        if (response.data) {
          SuccessToast("Forex created succesfully");
          handleClose();
        } else {
          ErrorToast("Failed to create Forex");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to create Forex");
      });
  };

  const handleSubmit = async () => {
    await deleteForex(selectedForex?.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Forex deleted succesfully");
          handleClose();
        } else {
          ErrorToast("Failed to delete Forex");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete Forex");
      });
  };
  const columns = ForexTable({
    handleViewAction,
    handleEditAction,
    handleDeleteAction,
  });

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel]);

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(forexData?.data ?? []);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Products");
    XLSX.writeFile(workbook, "Forex.xlsx");
  };

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">Forex</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FiPlus />}
          sx={{ borderRadius: "20px" }}
          onClick={handleOpenForexModal}
        >
          Add Forex
        </Button>
      </Box>
      <ForexModal
        open={openForexModal}
        type={type}
        forex={selectedForex}
        handleClose={handleCloseForexModal}
        handleFormChange={handleFormChange}
        handleSubmitForex={
          type === "ADD" ? handleSubmitCreateForex : handleSubmitUpdateForex
        }
      />

      <CustomAlertDialog
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title={"Delete Forex"}
        subTitle={`Are you sure you want to delete ${selectedForex?.id}?`}
        negativeButtonText={"No"}
        positiveButtonText={"Yes"}
        icon={<FiTrash2 size={30} />}
        fullWidth={true}
      />
      <Box>
        <TableContainer component={Paper}>
          <DataGrid
            autoHeightf
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "gray",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
            slotProps={{
              panel: {
                sx: {
                  top: "-120px !important",
                },
              },
            }}
            filterMode="server"
            paginationMode="server"
            sortingMode="server"
            loading={isLoading}
            rowCount={forexData?.size ?? 0}
            columns={columns}
            rows={forexData?.data ?? []}
            getRowId={(row) => row.id}
            disableColumnSelector
            disableDensitySelector
            components={{
              Toolbar: () => <CustomToolbar data={forexData} />,
            }}
            sortModel={sortModel}
            paginationModel={paginationModel}
            filterModel={filterModel}
            onPaginationModelChange={handlePaginationModelChange}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
          />
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Forex;
