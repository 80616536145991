import { Box, Paper, TableContainer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { MerchantPurchaseOrderTable } from "../data/MerchantPurchaseOrderTable";
import {
  useSearchMerchantPurchaseOrderQuery,
  useUpdateMerchantPurchaseOrderMutation,
} from "../services/MerchantPurchaseOrderService";
import OrderStatusModal from "../components/modals/OrderStatusModal";
import { DomainType } from "../presentation/helpers";
import { DataGridService } from "../core/dataGridService";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { QuestionAnswer, Warning } from "@mui/icons-material";
import CustomToolbar from "../components/common/CustomToolbar";

const MerchantPurchaseOrders = () => {
  const dataGridService = new DataGridService("MerchantPurchaseOrders");

  const [selectedMerchantPurchaseOrder, setSelectedMerchantPurchaseOrder] =
    useState(null);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openSubmitPurchaseOrder, setOpenSubmitPurchaseOrder] = useState(false);

  const [filter, setFilter] = useState({});

  const {
    data: merchantPurchaseOrder,
    isLoading: isMerchantPurchaseOrderLoading,
  } = useSearchMerchantPurchaseOrderQuery(filter);

  const [updateOrderStatus] = useUpdateMerchantPurchaseOrderMutation();
  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState(null);

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleClosePurchaseOrder = () => {
    setOpenStatusModal(false);
    setOpenSubmitPurchaseOrder(false);
    setSelectedMerchantPurchaseOrder(null);
  };
  const handleCloseConfirmDialog = () => {
    setOpenSubmitPurchaseOrder(false);
  };

  const handleOpenConfirmDialog = () => {
    setOpenSubmitPurchaseOrder(true);
  };

  const handleEditAction = (row) => {
    setSelectedMerchantPurchaseOrder(row);
    setOpenStatusModal(true);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setSelectedMerchantPurchaseOrder((prev) => ({
      ...prev,
      order: { ...prev.order, [name]: value },
    }));
  };

  const handleUpdateMerchantOrder = async () => {
    await updateOrderStatus(selectedMerchantPurchaseOrder?.order)
      .then((response) => {
        if (response.data) {
          SuccessToast("Order Updated succesfully");
        } else {
          ErrorToast("Failed to update order");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update order");
      })
      .finally(() => {
        handleClosePurchaseOrder();
      });
  };

  const getIdMerchant = () => {
    const user = JSON.parse(sessionStorage.getItem("userDetails"));
    if (
      [DomainType.MERCHANT_ADMIN, DomainType.EMPLOYEE].includes(user.domainType)
    )
      return user?.merchant;
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel.page,
      size: paginationModel.pageSize,
      merchantId: getIdMerchant(),
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };

  const columns = MerchantPurchaseOrderTable({
    handleEditAction,
  });

  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel]);

  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">Merchant Purchase Order</Typography>
      </Box>

      <Box>
        <TableContainer component={Paper}>
          <DataGrid
            autoHeight
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "gray",
              "& .MuiDataGrid-cell:hover": {
                color: "gray",
              },
            }}
            filterMode="server"
            paginationMode="server"
            loading={isMerchantPurchaseOrderLoading}
            rowCount={merchantPurchaseOrder?.size ?? 0}
            columns={columns}
            rows={merchantPurchaseOrder?.data ?? []}
            getRowId={(row) => row.id}
            disableColumnSelector
            disableDensitySelector
            slotProps={{
              panel: {
                sx: {
                  top: "-120px !important",
                },
              },
            }}
            components={{
              Toolbar: () => <CustomToolbar data={merchantPurchaseOrder} />,
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
          />
        </TableContainer>{" "}
        <OrderStatusModal
          open={openStatusModal}
          order={selectedMerchantPurchaseOrder}
          handleClose={handleClosePurchaseOrder}
          handleFormChange={handleFormChange}
          handleSubmit={handleOpenConfirmDialog}
        />
        <CustomAlertDialog
          open={openSubmitPurchaseOrder}
          handleClose={handleCloseConfirmDialog}
          handleSubmit={handleUpdateMerchantOrder}
          title={"Update status"}
          subTitle={`Are you sure you want to update status from ${selectedMerchantPurchaseOrder?.prevStatus} to ${selectedMerchantPurchaseOrder?.order?.status}?`}
          negativeButtonText={"No"}
          positiveButtonText={"Yes"}
          icon={<Warning />}
          fullWidth={true}
        />
      </Box>
    </Box>
  );
};

export default MerchantPurchaseOrders;
