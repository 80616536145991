import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Checkbox,
  Grid,
  InputLabel,
  FormControl,
  FormControlLabel,
  FormGroup,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Stack,
} from "@mui/material";
import { FiTrash2 } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import {
  createProductConfigurationRequest,
  updateProductConfigurationRequest,
} from "../models/productConfiguration";
import {
  useCreateProductConfigurationMutation,
  useDeleteProductConfigurationMutation,
  useUpdateProductConfigurationMutation,
} from "../services/ProductConfigurationService";
import { useProductContext } from "../presentation/shared/hooks/useProductContextHook";
import LoadingPage from "../components/LoadingPage";
import {
  refundPolicies,
  retryPolicies,
} from "../components/common/dropdownsData";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const EditProductConfiguration = () => {
  const { id: productId } = useParams();
  const {
    productData: { configuration },
    refetchProduct,
    isProductSuccess,
  } = useProductContext();
  const [updateProductConfig, { isLoading: isUpdateProductConfigLoading }] =
    useUpdateProductConfigurationMutation();
  const [createProductConfig, { isLoading: isCreateProductConfigLoading }] =
    useCreateProductConfigurationMutation();
  const [deleteProductConfig] = useDeleteProductConfigurationMutation();
  const [productConfigRequest, setProductConfigRequest] = useState(null);
  const [open, setOpen] = useState(false);

  const handleCloseProductConfigDialog = () => {
    setOpen(false);
  };
  const handleShowProductConfigDialog = () => {
    setOpen(true);
  };
  const handleDeleteProductConfig = async (event) => {
    event.preventDefault();

    await deleteProductConfig(configuration.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("Product configuration deleted succesfully");
          refetchProduct();
        } else {
          ErrorToast("Failed to delete product configuration ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete product configuration ");
      });
  };

  const handleSetupProductConfiguationRequest = () => {
    setProductConfigRequest((prevProductConfig) => ({
      ...createProductConfigurationRequest,
      serviceId: productId,
    }));
  };

  const handleProductConfigChange = (event) => {
    const {
      target: { name, value, checked },
    } = event;

    if (
      [
        "inputWeightRequired",
        "inputVolumeRequired",
        "inputMeasurementRequired",
        "inputDeliveryContactRequired",
        "inputDeliveryAddressRequired",
        "enabled",
      ].includes(name)
    ) {
      setProductConfigRequest((prevProductConfig) => ({
        ...prevProductConfig,
        [name]: checked,
      }));
    } else {
      setProductConfigRequest((prevProductConfig) => ({
        ...prevProductConfig,
        [name]: value,
      }));
    }
  };

  const handleUpdateProductConfiguration = async (event) => {
    event.preventDefault();

    await updateProductConfig(productConfigRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Product configuration updated succesfully");
          refetchProduct();
        } else {
          ErrorToast("Failed to update product configuration");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update product configuration");
      });
  };

  const handleCreateProductConfiguration = async (event) => {
    event.preventDefault();

    await createProductConfig(productConfigRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Product configuration created succesfully");
          refetchProduct();
        } else {
          ErrorToast("Failed to created product configuration ");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to created product configuration ");
      });
  };

  const initProductConfiguration = () => {
    if (configuration) {
      const toProductConfigurationRequest = updateProductConfigurationRequest;
      toProductConfigurationRequest.id = configuration.id;
      toProductConfigurationRequest.serviceId = productId;
      toProductConfigurationRequest.refundPolicy = configuration.refundPolicy;
      toProductConfigurationRequest.retryPolicy = configuration.retryPolicy;
      toProductConfigurationRequest.inputWeightRequired =
        configuration.inputWeightRequired;
      toProductConfigurationRequest.inputVolumeRequired =
        configuration.inputVolumeRequired;
      toProductConfigurationRequest.inputDeliveryAddressRequired =
        configuration.inputDeliveryAddressRequired;
      toProductConfigurationRequest.inputDeliveryContactRequired =
        configuration.inputDeliveryContactRequired;
      toProductConfigurationRequest.inputMeasurementRequired =
        configuration.inputMeasurementRequired;
      toProductConfigurationRequest.enabled = configuration.enabled;

      setProductConfigRequest(toProductConfigurationRequest);
    }
  };

  useEffect(() => {
    initProductConfiguration();
  }, [configuration]);

  if (productConfigRequest) {
    return (
      <Box>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
            maxWidth: "70%",
            margin: "0 auto",
            cursor: "pointer",
            overflow: "hidden",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6} sm={12} md={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small-label">
                  Refund Policy
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  label="Refund policy"
                  required
                  disabled={isUpdateProductConfigLoading}
                  value={productConfigRequest?.refundPolicy}
                  name="refundPolicy"
                  onChange={handleProductConfigChange}
                >
                  {refundPolicies.map((policy, index) => (
                    <MenuItem key={index} value={policy.value}>
                      {policy.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} sm={12} md={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-select-small-label">
                  Retry Policy
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  label="Retry policy"
                  required
                  disabled={isUpdateProductConfigLoading}
                  value={productConfigRequest.retryPolicy}
                  name="retryPolicy"
                  onChange={handleProductConfigChange}
                >
                  {retryPolicies.map((policy, index) => (
                    <MenuItem key={index} value={policy}>
                      {policy}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  name="inputWeightRequired"
                  disabled={isUpdateProductConfigLoading}
                  checked={productConfigRequest?.inputWeightRequired}
                  onChange={handleProductConfigChange}
                  control={<Checkbox />}
                  label="Does input require weight?"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  name="inputVolumeRequired"
                  disabled={isUpdateProductConfigLoading}
                  checked={productConfigRequest?.inputVolumeRequired}
                  onChange={handleProductConfigChange}
                  control={<Checkbox />}
                  label="Does input require volume?"
                />
              </FormGroup>
            </Grid>

            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  name="inputMeasurementRequired"
                  disabled={isUpdateProductConfigLoading}
                  checked={productConfigRequest?.inputMeasurementRequired}
                  onChange={handleProductConfigChange}
                  control={<Checkbox />}
                  label="Does input require dimensions?"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                <span>
                  <FormControlLabel
                    name="inputDeliveryContactRequired"
                    disabled={isUpdateProductConfigLoading}
                    checked={productConfigRequest?.inputDeliveryContactRequired}
                    onChange={handleProductConfigChange}
                    control={<Checkbox />}
                    label="Does require delivery contact?"
                  />
                </span>
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  name="inputDeliveryAddressRequired"
                  disabled={isUpdateProductConfigLoading}
                  checked={productConfigRequest?.inputDeliveryAddressRequired}
                  onChange={handleProductConfigChange}
                  control={<Checkbox />}
                  label="Does it require delivery address?"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  name="enabled"
                  disabled={isUpdateProductConfigLoading}
                  checked={productConfigRequest?.enabled}
                  onChange={handleProductConfigChange}
                  control={<Checkbox />}
                  label="Enable"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Box
            sx={{ display: "flex", mt: 10, justifyContent: "space-between" }}
          >
            <Link to={"/pages/products"}>
              <Button
                disabled={isUpdateProductConfigLoading}
                variant="contained"
              >
                {(isCreateProductConfigLoading ||
                  isUpdateProductConfigLoading) && (
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "5px",
                    }}
                  />
                )}
                Cancel
              </Button>
            </Link>

            <Stack direction={"row"} gap={2}>
              <Button
                onClick={handleShowProductConfigDialog}
                sx={{ float: "right" }}
                color="error"
                variant="contained"
              >
                Delete
              </Button>
              <Button
                disabled={isUpdateProductConfigLoading}
                variant="contained"
                onClick={
                  configuration
                    ? handleUpdateProductConfiguration
                    : handleCreateProductConfiguration
                }
              >
                {(isCreateProductConfigLoading ||
                  isUpdateProductConfigLoading) && (
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "5px",
                    }}
                  />
                )}
                Save
              </Button>
            </Stack>
          </Box>
        </Paper>
        <CustomAlertDialog
          open={open}
          handleClose={handleCloseProductConfigDialog}
          handleSubmit={handleDeleteProductConfig}
          title={"Delete Product configuration"}
          subTitle={`Are you sure you want to delete product configuration for this product?`}
          negativeButtonText={"No"}
          positiveButtonText={"Yes"}
          icon={<FiTrash2 size={30} />}
          fullWidth={true}
        />
      </Box>
    );
  } else if (isProductSuccess || !productConfigRequest) {
    return (
      <Button
        variant="contained"
        onClick={handleSetupProductConfiguationRequest}
      >
        Add Configuration
      </Button>
    );
  } else {
    return <LoadingPage />;
  }
};

export default EditProductConfiguration;
