import {
  Box,
  Grid,
  Paper,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useGetRemittanceJobQuery,
  useTriggerRemittanceAttemptMutation,
} from "../services/RemittanceJobService";
import moment from "moment/moment";
import LoadingPage from "../components/LoadingPage";
import { RiExchangeBoxFill } from "react-icons/ri";
import { KeyboardBackspace } from "@mui/icons-material";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { FiDelete, FiTrigger } from "react-icons/fi";

const SingleRemittanceJob = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetRemittanceJobQuery(id);
  const [handleTriggerClick] = useTriggerRemittanceAttemptMutation();
  const navigate = useNavigate();

  const [openTrigger, setOpenTrigger] = React.useState(false);

  const handleOpenTrigger = () => {
    setOpenTrigger(true);
  };
  const handleCloseTrigger = () => {
    setOpenTrigger(false);
  };
  if (data) {
    const { data: remittanceJob } = data;

    const handleTriggerButtonClick = async () => {
      const triggerRemittanceAttemptRequest = {
        provider: "instant credit",
        orderId: remittanceJob?.userPOId,
      };

      await handleTriggerClick(triggerRemittanceAttemptRequest)
        .then((response) => {
          if (response.data) {
            SuccessToast("Remittance attempt triggered successfully");
            handleCloseTrigger();
            navigate(0);
          } else {
            ErrorToast("Failed to trigger remittance attempt");
            handleCloseTrigger();
          }
        })
        .catch((error) => {
          ErrorToast("Failed to trigger remittance attempt");
        });
    };
    return (
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Box
          sx={{ display: "flex", justifyContent: "space-between", pb: "20px" }}
        >
          <Link to={-1}>
            <Button variant="outlined">
              <KeyboardBackspace /> BACK
            </Button>
          </Link>

          <Typography variant="h5">Remittance Job Details</Typography>
        </Box>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
          }}
        >
          {remittanceJob?.status === "CREATED" && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" onClick={handleOpenTrigger}>
                Retrigger
              </Button>
            </Box>
          )}

          <Grid
            container
            spacing={3}
            sx={{ mt: 1, justifyContent: "space-evenly" }}
          >
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box>
                  <CustomAlertDialog
                    open={openTrigger}
                    handleClose={handleCloseTrigger}
                    handleSubmit={handleTriggerButtonClick}
                    title={"Retrigger "}
                    subTitle={`Are you sure you want to Retrigger ${
                      remittanceJob?.userPOId ?? "N/A"
                    }?`}
                    negativeButtonText={"No"}
                    positiveButtonText={"Yes"}
                    icon={<FiDelete size={30} />}
                    fullWidth={true}
                  />
                  <IconButton
                    sx={{
                      color: "#049bf5",
                      backgroundColor: "rgba(4,155,245, 0.2) !important",
                    }}
                  >
                    <RiExchangeBoxFill />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontSize: "20px", mb: 2 }}>
                    Remittance Job
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    ID:{" "}
                    <span style={{ opacity: 0.7 }}>{remittanceJob?.id}</span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Order Id:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {remittanceJob?.orderId}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Order Item:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {remittanceJob?.orderItem}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Status:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {remittanceJob?.status}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Configuration Id:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {remittanceJob?.remittanceConfigurationId}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Closed:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {remittanceJob?.closed ? "YES" : "NO"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    User POId:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {remittanceJob?.userPOId}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Last Modified By:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {remittanceJob?.lastModifiedBy ?? "N/A"}
                    </span>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Date Closed:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {remittanceJob?.dateClosed
                        ? moment(remittanceJob?.dateClosed).format("LLL")
                        : "N/A"}
                    </span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Date Created:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(remittanceJob?.created).format("LLL")}
                    </span>
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "15px", mb: 2 }}
                  >
                    Date Updated:{" "}
                    <span style={{ opacity: 0.7 }}>
                      {moment(remittanceJob?.updated).format("LLL")}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default SingleRemittanceJob;
