import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Grid,
  Select,
  Typography,
  Switch,
} from "@mui/material";
import { useGetAllCurrencyQuery } from "../../services/CurrencyService";
import queryString from "query-string";
import moment from "moment";
import timezones from "../../core/timezones";
import ReactToPrint from "react-to-print";
import { Print } from "@mui/icons-material";

const CountryModal = ({
  open,
  handleClose,
  country,
  type,
  handleFormChange,
  handleSubmitCountry,
}) => {
  const filter = queryString.stringify({ size: 10000 });
  const printRef = React.useRef();
  const { data: currencies } = useGetAllCurrencyQuery(filter);

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        sx={{ mx: "auto", my: "0px" }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle> {`${type} COUNTRY`}</DialogTitle>
          {type === "VIEW" && (
            <ReactToPrint
              trigger={() => (
                <Button>
                  <Print />
                </Button>
              )}
              content={() => printRef.current}
            />
          )}
        </Box>
        <DialogContent ref={printRef}>
          {type === "VIEW" ? (
            <Box>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Id: <span style={{ opacity: 0.7 }}>{country?.id ?? "n/a"}</span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Name:{" "}
                <span style={{ opacity: 0.7 }}>{country?.name ?? "n/a"}</span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                ISO Code:{" "}
                <span style={{ opacity: 0.7 }}>
                  {country?.isoCode ?? "n/a"}
                </span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Time Zone:{" "}
                <span style={{ opacity: 0.7 }}>
                  {country?.timeZone ?? "n/a"}
                </span>
              </Typography>
              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Calling Code:{" "}
                <span style={{ opacity: 0.7 }}>
                  {country?.callingCode ?? "n/a"}
                </span>
              </Typography>

              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Region Label:{" "}
                <span style={{ opacity: 0.7 }}>
                  {country?.regionLabel ?? "n/a"}
                </span>
              </Typography>

              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Region Initials:{" "}
                <span style={{ opacity: 0.7 }}>
                  {country?.regionInitial ?? "n/a"}
                </span>
              </Typography>

              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Created:{" "}
                <span style={{ opacity: 0.7 }}>
                  {country?.created
                    ? moment(country?.created).format("LLLL")
                    : "n/a"}
                </span>
              </Typography>

              <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
                Updated:{" "}
                <span style={{ opacity: 0.7 }}>
                  {country?.updated
                    ? moment(country?.created).format("LLLL")
                    : "n/a"}
                </span>
              </Typography>
            </Box>
          ) : (
            <Grid container spacing={1} padding={1}>
              <Grid item md={6} xs={12} sm={12}>
                <TextField
                  label="Name"
                  name="name"
                  variant="outlined"
                  value={country?.name}
                  onChange={handleFormChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item md={6} xs={12} sm={12}>
                <TextField
                  label="ISO Code"
                  name="isoCode"
                  variant="outlined"
                  value={country?.isoCode}
                  onChange={handleFormChange}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item md={6} xs={12} sm={12}>
                <FormControl fullWidth size="small" required>
                  <InputLabel id="demo-select-small-label">
                    Time Zone
                  </InputLabel>
                  <Select
                    label="Time Zone"
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    required
                    value={country?.timeZone}
                    name="timeZone"
                    onChange={handleFormChange}
                  >
                    {timezones.map((timeZone, index) => (
                      <MenuItem value={timeZone} key={index}>
                        {timeZone}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12} sm={12}>
                <TextField
                  label="Calling Code"
                  name="callingCode"
                  variant="outlined"
                  fullWidth
                  value={country?.callingCode}
                  size="small"
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item md={6} xs={12} sm={12}>
                <TextField
                  label="Region Label"
                  name="regionLabel"
                  variant="outlined"
                  fullWidth
                  value={country?.regionLabel}
                  size="small"
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item md={6} xs={12} sm={12}>
                <TextField
                  label="Region Initials"
                  name="regionInitial"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={country?.regionInitial}
                  onChange={handleFormChange}
                />
              </Grid>
              <Grid item md={6} xs={12} sm={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch/>}
                      label="Allow Sending"
                      size="small"
                      checked={country?.sendingAllowed}
                      name="sendingAllowed"
                      onChange={handleFormChange}
                    />
                  </FormGroup>
                </Grid>
                <Grid item md={6} xs={12} sm={12}>
                  <FormGroup>
                  <FormControlLabel
                      control={<Switch />}
                      label="Allow Deposit"
                      size="small"
                      checked={country?.depositAllowed}
                      name="depositAllowed"
                      onChange={handleFormChange}
                    />
                  </FormGroup>
                </Grid>
              
              {type === "UPDATE" && (
                <Grid item md={6} xs={12} sm={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Enabled"
                      size="small"
                      checked={country?.enabled}
                      name="enabled"
                      onChange={handleFormChange}
                    />
                  </FormGroup>
                </Grid>
              )}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          {type !== "VIEW" ? (
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button color="error" onClick={handleClose} variant="contained">
                CANCEL
              </Button>
              <Button onClick={handleSubmitCountry} variant="contained">
                {type}
              </Button>
            </Box>
          ) : (
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button onClick={handleClose} variant="contained">
                OK
              </Button>
            </Box>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CountryModal;
