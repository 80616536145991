import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  TextField,
  Typography,
  Grid,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { useUpdateMerchantMutation } from "../services/MerchantService";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../components/LoadingPage";
import { useGetAllCountriesQuery } from "../services/CountryService";
import queryString from "query-string";
import { getEmojiFlag } from "countries-list";
import GoogleMapReact from "google-map-react";
import Marker from "../components/common/Marker";
import { City } from "country-state-city";

import { GOOGLE_MAP_API_KEY } from "../core/api-key";
import { merchantContact } from "../models/merchantContact";
import { useMerchantContext } from "../presentation/shared/hooks/useMerchantContext";
import { ErrorToast, SuccessToast } from "../components/common/Toast";

const EditMerchantDetails = () => {
  const MAXDATA = queryString.stringify({ size: 10000 });
  const [updateMerchant] = useUpdateMerchantMutation();
  const { merchantData } = useMerchantContext();
  const { data: countries } = useGetAllCountriesQuery(MAXDATA);

  const [merchantRequest, setMerchantRequest] = useState(null);
  const defaultProps = {
    center: {
      lat: merchantRequest?.address?.gpsLocation?.latitude ?? 5.612218041361714,
      lng:
        merchantRequest?.address?.gpsLocation?.longitude ??
        -0.20050925221460147,
    },
    zoom: 11,
  };

  const [image, setImage] = useState("");
  const navigate = useNavigate();
  const [merchantContactRequest, setMerchantContactRequest] =
    useState(merchantContact);

  const [openDeleteDocumentDialog, setOpenDeleteDocumentDialog] =
    useState(false);
  const [openDocumentDialog, setOpenDocumentDialog] = useState(false);
  const [openWarehouseDialog, setOpenWarehouseDialog] = useState(false);

  const handleFormChange = (event) => {
    const { name, value, checked } = event.target;
    if (["verified", "active"].includes(name)) {
      setMerchantRequest((prev) => ({ ...prev, [name]: checked }));
    } else if (
      ["streetAddress", "postalAddress", "city", "regionOrState"].includes(name)
    ) {
      setMerchantRequest((prev) => ({
        ...prev,
        address: { ...prev?.address, [name]: value },
      }));
    } else if (["countryCode"].includes(name)) {
      setMerchantRequest((prev) => ({
        ...prev,
        [name]: value.isoCode,
        address: { ...prev?.address, country: value.name },
      }));
    } else {
      setMerchantRequest((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSelectedCountry = (country) => {
    setMerchantRequest((prev) => ({
      ...prev,
      countryCode: country.isoCode,
      address: { ...prev?.address, country: country.name },
    }));
  };
  const handleGPSChange = (event) => {
    const { lat, lng } = event.center;
    setMerchantRequest((prev) => ({
      ...prev,
      address: {
        ...prev?.address,
        gpsLocation: {
          ...prev?.address?.gpsLocation,
          latitude: lat,
          longitude: lng,
        },
      },
    }));
  };
  const handleCancel = () => {
    navigate("/pages/merchants");
  };
  const handleSubmitMerchant = async (event) => {
    event.preventDefault();

    await updateMerchant(merchantRequest)
      .then((response) => {
        if (response.data) {
          SuccessToast("Merchant updated succesfully");
          navigate("/pages/merchants");
        } else {
          ErrorToast("Failed to update Merchant");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to update Merchant");
      });
  };

  const initMerchantRequest = () => {
    if (merchantData) {
      setMerchantRequest(merchantData);
    }
  };
  React.useEffect(() => {
    initMerchantRequest();
  }, [merchantData]);

  if (merchantRequest) {
    return (
      <Box sx={{ pt: "80px", pb: "20px" }}>
        <Paper
          sx={{
            boxShadow: "none !important",
            borderRadius: "12px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "divider",
            p: "20px",
            maxWidth: "100%",
            margin: "0 auto",
            overflow: "hidden",
          }}
        >
          <Typography
            sx={{
              marginBottom: "14px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            variant="h6"
          >
            Edit Merchant
          </Typography>
          <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
            <TextField
              label="Merchant name"
              variant="outlined"
              name="name"
              value={merchantRequest?.name}
              onChange={handleFormChange}
              fullWidth
              size="small"
            />

            <TextField
              label="Street Address"
              variant="outlined"
              name="streetAddress"
              value={merchantRequest?.address?.streetAddress}
              onChange={handleFormChange}
              fullWidth
              size="small"
            />
          </Box>
          <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
            <FormControl fullWidth size="small" required>
              <InputLabel id="demo-select-small-label">Country</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                label="Country"
                value={merchantRequest?.address?.country ?? ""}
              >
                {countries?.map((country, index) => (
                  <MenuItem
                    key={index}
                    value={country.name}
                    onClick={() => {
                      handleSelectedCountry(country);
                    }}
                  >{`${getEmojiFlag(country?.isoCode)} ${
                    country?.name
                  }`}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth size="small" required>
              <InputLabel id="demo-simple-select-label">City</InputLabel>
              <Select
                name="city"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                disabled={!merchantRequest?.address?.country}
                label="City"
                value={merchantRequest?.address?.city ?? ""}
                onChange={handleFormChange}
              >
                {merchantRequest?.countryCode &&
                  City.getCitiesOfCountry(merchantRequest?.countryCode)?.map(
                    (city, index) => (
                      <MenuItem key={index} value={city.name}>
                        {city.name}
                      </MenuItem>
                    )
                  )}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ mt: 4, display: "flex", alignItems: "center", gap: 4 }}>
            <TextField
              label="Postal Address"
              variant="outlined"
              name="postalAddress"
              value={merchantRequest?.address?.postalAddress}
              onChange={handleFormChange}
              fullWidth
              size="small"
            />

            <TextField
              label="Region/State"
              variant="outlined"
              name="regionOrState"
              value={merchantRequest?.address?.regionOrState}
              onChange={handleFormChange}
              fullWidth
              size="small"
            />
          </Box>

          <Box sx={{ mt: 4, height: "400px", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              onChange={handleGPSChange}
            >
              <Marker
                lat={defaultProps.center.lat}
                lng={defaultProps.center.lng}
              />
            </GoogleMapReact>
          </Box>

          {/* <Box sx={{ mt: 4, alignItems: "center", gap: 5 }}>
            <FormGroup>
              <span>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Activate merchant after creation"
                  name="active"
                  size="small"
                  checked={merchantRequest?.active}
                  onChange={handleFormChange}
                />
              </span>
            </FormGroup>
            <FormGroup>
              <span>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Verified merchant after creation"
                  name="verified"
                  checked={merchantRequest?.verified}
                  size="small"
                  onChange={handleFormChange}
                />
              </span>
            </FormGroup>
          </Box> */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "30px",
            }}
          >
            <Button onClick={handleCancel} variant="contained" color="error">
              Cancel
            </Button>
            <Button onClick={handleSubmitMerchant} variant="contained">
              Save
            </Button>
          </Box>

          <Grid item xs={12} md={6} xl={6} sm={12}>
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 4, mb: 2 }}
            >
              <Grid container spacing={2} mt={3}></Grid>
            </Box>
          </Grid>
        </Paper>
      </Box>
    );
  } else {
    return <LoadingPage />;
  }
};

export default EditMerchantDetails;
