import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TableContainer,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  useDeleteDisputesActivityLogMutation,
  useSearchDisputesActivityLogsQuery,
} from "../services/DisputesActivityLogService";
import CustomAlertDialog from "../components/modals/CustomAlertDialog";
import { DisputesActivityLogTable } from "../data/DisputesActivityLogTable";
import { useEffect } from "react";
import moment from "moment";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { Print } from "@mui/icons-material";
import { ErrorToast, SuccessToast } from "../components/common/Toast";
import { DataGridService } from "../core/dataGridService";
import CustomToolbar from "../components/common/CustomToolbar";

const DisputesActivityLog = () => {
  const dataGridService = new DataGridService("ProcurementActivity");

  const [filter, setFilter] = useState({});
  const [selectedDisputesActivityLog, setSelectedDisputesActivityLog] =
    useState(null);
  const [open, setOpen] = useState(false);
  const { data, isLoading } = useSearchDisputesActivityLogsQuery(filter);
  const [deleteDisputesActivityLog] = useDeleteDisputesActivityLogMutation();

  const [paginationModel, setPaginationModel] = useState(
    dataGridService.getPaginationModel()
  );
  const [sortModel, setSortModel] = useState(dataGridService.getSortModel());
  const [filterModel, setFilterModel] = useState(
    dataGridService.getFilterModel()
  );

  const printRef = useRef();

  const handlePaginationModelChange = (paginationModel) => {
    dataGridService.setPaginationModel(paginationModel);
    setPaginationModel(paginationModel);
  };
  const handleFilterModelChange = (filterModel) => {
    dataGridService.setFilterModel(filterModel);
    setFilterModel(filterModel);
  };
  const handleSortModelChange = (sortModel) => {
    dataGridService.setSortModel(sortModel);
    setSortModel(sortModel);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedDisputesActivityLog(null);
  };

  const handleViewActivity = async (row) => {
    setOpen(true);
    setSelectedDisputesActivityLog(row);
  };

  const handleDeleteAction = async (row) => {
    setOpen(true);
    setSelectedDisputesActivityLog(row);
  };

  const handleSetFilter = () => {
    setFilter((prev) => ({
      ...prev,
      page: paginationModel.page,
      size: paginationModel.pageSize,
      [filterModel?.items[0]?.field]: filterModel?.items[0]?.value,
      sortOrder: sortModel[0]?.sort ? sortModel[0].sort.toUpperCase() : "DESC",
      sortBy: sortModel[0]?.field ?? "created",
    }));
  };

  const handleSubmit = async () => {
    await deleteDisputesActivityLog(selectedDisputesActivityLog.id)
      .then((response) => {
        if (response.data) {
          SuccessToast("DisputesActivityLog deleted succesfully");
        } else {
          ErrorToast("Failed to delete DisputesActivityLog");
        }
      })
      .catch((error) => {
        ErrorToast("Failed to delete DisputesActivityLog");
      });
  };
  const columns = DisputesActivityLogTable({ handleViewActivity });
  useEffect(() => {
    handleSetFilter();
  }, [paginationModel, filterModel, sortModel]);
  return (
    <Box sx={{ pt: "80px", pb: "20px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h6">Disputes Activity Log</Typography>
      </Box>
      <CustomAlertDialog
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        title={"Delete DisputesActivityLog"}
        subTitle={`Are you sure you want to delete ${selectedDisputesActivityLog?.id}?`}
        negativeButtonText={"No"}
        positiveButtonText={"Yes"}
        icon={<FiTrash2 size={30} />}
        fullWidth={true}
      />
      <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
        sx={{ mx: "auto", my: "0px" }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>Activity Details </DialogTitle>
          <ReactToPrint
            trigger={() => (
              <Button>
                <Print />
              </Button>
            )}
            content={() => printRef.current}
          />
        </Box>{" "}
        <DialogContent ref={printRef}>
          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
            Id:{" "}
            <span style={{ opacity: 0.7 }}>
              {selectedDisputesActivityLog?.id}
            </span>
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
            Component:{" "}
            <span style={{ opacity: 0.7 }}>
              {selectedDisputesActivityLog?.component ?? "N/A"}
            </span>
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
            Case Id:{" "}
            <span style={{ opacity: 0.7 }}>
              {selectedDisputesActivityLog?.orderItemId ?? "N/A"}
            </span>
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
            Dsipute Activity:{" "}
            <span style={{ opacity: 0.7 }}>
              {selectedDisputesActivityLog?.activity}
            </span>
          </Typography>

          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
            Request:{" "}
            <span style={{ opacity: 0.7 }}>
              {selectedDisputesActivityLog?.request ?? "null"}
            </span>
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
            Response:{" "}
            <span style={{ opacity: 0.7 }}>
              {selectedDisputesActivityLog?.response ?? "null"}
            </span>
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
            Created:{" "}
            <span style={{ opacity: 0.7 }}>
              {moment(selectedDisputesActivityLog?.created).format("LLL") ??
                "N/A"}
            </span>
          </Typography>
          <Typography variant="subtitle2" sx={{ fontSize: "15px", mb: 2 }}>
            Updated:{" "}
            <span style={{ opacity: 0.7 }}>
              {moment(selectedDisputesActivityLog?.updated).format("LLL") ??
                "N/A"}
            </span>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ok</Button>
        </DialogActions>
      </Dialog>
      <Box>
        <TableContainer component={Paper}>
          <DataGrid
            autoHeight
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "gray",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
            filterMode="server"
            paginationMode="server"
            sortingMode="server"
            loading={isLoading}
            rowCount={data?.size ?? 0}
            columns={columns}
            rows={data?.data ?? []}
            getRowId={(row) => row.id}
            disableColumnSelector
            disableDensitySelector
            slotProps={{
              panel: {
                sx: {
                  top: "-120px !important",
                },
              },
            }}
            components={{
              Toolbar: () => <CustomToolbar data={data} />,
            }}
            sortModel={sortModel}
            paginationModel={paginationModel}
            filterModel={filterModel}
            onPaginationModelChange={handlePaginationModelChange}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
          />
        </TableContainer>
      </Box>
    </Box>
  );
};

export default DisputesActivityLog;
